import { AnyAction } from 'redux';
import { createReducer } from 'redux-create-reducer';

import {
  ClickAway,
  FetchAllProductsInstances,
  SetBootstrapConfig,
  SetNavigateConfig,
  FetchAllUsers,
  FetchFeatureFlags,
} from './actions';
import { ICommon } from '../../interfaces/ICommon';
import { SetCurrentUserId } from '../users/actions';

export const initialState: ICommon = {
  bootstrap: {
    isStandalone: false,
    basePath: null,
  },
  navigation: {
    enterpriseId: null,
    pathname: null,
    redirectPath: null,
    navigateFn: () => null,
  },
  auth: {
    signOutCallbackFn: () => null,
    userId: null,
  },
  productInstances: {
    items: [],
  },
  allUsers: [],
  allRoles: [],
  // allGroups: [] // -- TODO Implement setting allGroups,
  featureFlags: [],
};

export default createReducer(initialState, {
  [SetBootstrapConfig.TYPE]: (state: ICommon, { payload }: AnyAction) => ({
    ...state,
    bootstrap: {
      ...state.bootstrap,
      ...payload,
    },
  }),

  [SetNavigateConfig.TYPE]: (state: ICommon, { payload }: AnyAction) => ({
    ...state,
    navigation: {
      ...state.navigation,
      ...payload,
    },
  }),

  [ClickAway.TYPE]: (
    state: ICommon,
    { payload: { path, signOutCallback } }: AnyAction
  ) => ({
    ...state,
    navigation: {
      ...state.navigation,
      redirectPath: `${path}?enterpriseId=${state.navigation.enterpriseId}`,
    },
    auth: {
      ...state.auth,
      signOutCallbackFn: signOutCallback,
    },
  }),

  [FetchAllProductsInstances.Success.TYPE]: (
    state: ICommon,
    { payload }: AnyAction
  ) => ({
    ...state,
    productInstances: {
      items: payload,
    },
  }),

  [FetchAllUsers.Success.TYPE]: (state: ICommon, { payload }: AnyAction) => ({
    ...state,
    allUsers: payload,
  }),

  [FetchFeatureFlags.Success.TYPE]: (
    state: ICommon,
    { payload }: AnyAction
  ) => ({
    ...state,
    featureFlags: payload,
  }),

  [SetCurrentUserId.TYPE]: (state: ICommon, { payload }: AnyAction) => ({
    ...state,
    auth: {
      ...state.auth,
      userId: payload,
    },
  }),
});
