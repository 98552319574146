import { combineReducers } from 'redux';

import idpReducer from 'src/app/components/idp-management/reducer';
import commonReducer from '../../components/common/reducer';
import enterprisesReducer from '../../components/enterprises/reducer';
import { errorsReducer } from '../../components/errors/reducer';
import groupsReducer from '../../components/groups/reducer';
import { loaderReducer } from '../../components/loader/reducer';
import overviewReducer from '../../components/overview/store/reducer';
import userRolesReducer from '../../components/user-roles/reducer';
import usersReducer from '../../components/users/reducer';
import configReducer from '../../config/reducer';

const createRootReducer = (): any =>
  combineReducers({
    enterprises: enterprisesReducer,
    overview: overviewReducer,
    users: usersReducer,
    userRoles: userRolesReducer,
    groups: groupsReducer,
    common: commonReducer,
    config: configReducer,
    loader: loaderReducer,
    errors: errorsReducer,
    idp: idpReducer,
  });

export default createRootReducer;
