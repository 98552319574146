import { AxiosResponse } from 'axios';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ClickAway,
  FetchAllProductsInstances,
  FetchAllUsers,
  FetchFeatureFlags,
} from './actions';
import { getAuth, getNavigation } from './selectors';
import { phanesAPIClient, prometheusAPIClient } from '../../api/clients';
import { IActionWithPayload } from '../../core/redux/interfaces';
import { IFetchAllProductsInstances, IUser } from '../../interfaces/ICommon';
import { notifyFormPopulated } from '../../services/formPopulated';
import { getEnterpriseId } from '../enterprises/selectors';
import {
  ClearMapGroupsStepper,
  CloseMapGroupsErrorBanner,
  ClearEditGroup,
  ShowDiscardChangesModal,
  HideDiscardChangesModal,
  DiscardAddGroupChanges,
} from '../groups/actions';
import { getIsAddGroupDirty } from '../groups/selectors';
import {
  ClearEditingUserRole,
  ClearRolesTableConfigData,
  ClearSelectedUserRoles,
  DiscardCustomRoleStepper,
  HideUserRoleNotification,
  ToggleDiscardChangesModal as ToggleDiscardCustomRoleModal,
} from '../user-roles/actions';
import { getIsCustomRoleDirty } from '../user-roles/selectors';
import {
  ClearUsers,
  DiscardInviteChanges,
  ToggleDiscardChangesModal as ToggleDiscardUserDetailsModal,
} from '../users/actions';
import { getIsUserDetailsDirty } from '../users/selectors';

type TFetchAllProductsInstancesResp =
  | AxiosResponse<{ data: IFetchAllProductsInstances }>
  | any;

export function* handleClickAway(
  action: IActionWithPayload<{ origin: string; shouldRedirect: boolean }>
): Generator {
  const {
    payload: { shouldRedirect = true },
  } = action;
  const { signOutCallbackFn } = (yield select(getAuth)) as any;
  const { navigateFn, redirectPath, pathname } = (yield select(
    getNavigation
  )) as any;

  const isGroupsActive = pathname.includes('groups');
  const isEditGroupActive = pathname.includes('edit-group');
  const isUsersActive = pathname.includes('users');
  const isUserRolesActive =
    pathname.includes('user-roles') || pathname.includes('edit-user-role');

  const isAddGroupDirty = (yield select(getIsAddGroupDirty)) as boolean;
  const isUserDetailsDirty = (yield select(getIsUserDetailsDirty)) as boolean;
  const isCustomRoleDirty = (yield select(getIsCustomRoleDirty)) as boolean;

  if (isGroupsActive && isAddGroupDirty) {
    yield put(ShowDiscardChangesModal());
    return;
  }

  if (isUsersActive && isUserDetailsDirty) {
    yield put(ToggleDiscardUserDetailsModal(true));
    return;
  }

  if (isUserRolesActive && isCustomRoleDirty) {
    yield put(ToggleDiscardCustomRoleModal(true));
    return;
  }

  if (signOutCallbackFn) {
    yield call(signOutCallbackFn);
    return;
  }

  if (shouldRedirect) {
    if (isGroupsActive && !isAddGroupDirty) {
      yield put(ClearMapGroupsStepper());
    }
    if (isGroupsActive) {
      yield put(CloseMapGroupsErrorBanner());
    }

    if (isUsersActive && !isUserDetailsDirty) {
      yield put(ClearUsers());
    }

    if (isUserRolesActive) {
      yield put(ClearRolesTableConfigData());
      yield put(ClearSelectedUserRoles());
      yield put(ClearEditingUserRole());
      yield put(HideUserRoleNotification());
    }

    if (isEditGroupActive) {
      yield put(ClearEditGroup());
    }

    yield call(navigateFn, redirectPath);
  }
}

export function* handleFetchFeatureFlags(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  try {
    if (!enterpriseId) {
      return;
    }

    const response = (yield prometheusAPIClient.get<string[]>(
      `/api/featureFlag/${enterpriseId}`
    )) as AxiosResponse<string[], any>;
    yield put(FetchFeatureFlags.SUCCESS(response.data));
  } catch (error) {
    yield put(FetchFeatureFlags.ERROR(error as Error));
  }
}

export function* handleDiscardChanges(
  action: IActionWithPayload<{ shouldRedirect: boolean }>
): Generator {
  const { payload: { shouldRedirect = true } = {} } = action;

  const { redirectPath, navigateFn, pathname } = (yield select(
    getNavigation
  )) as any;
  const { signOutCallbackFn } = (yield select(getAuth)) as any;

  yield call(notifyFormPopulated, { isDirty: false });

  if (pathname.includes('groups')) {
    yield put(HideDiscardChangesModal());
  }

  if (pathname.includes('users')) {
    yield put(ToggleDiscardUserDetailsModal(false));
  }

  if (pathname.includes('user-roles')) {
    yield put(ToggleDiscardCustomRoleModal(false));
  }

  if (signOutCallbackFn) {
    yield call(signOutCallbackFn);
    return;
  }

  if (shouldRedirect) {
    yield call(navigateFn, redirectPath);
  }
}

export function* handleFetchAllProductsInstances(): Generator {
  const enterpriseId = yield select(getEnterpriseId);

  try {
    const response: TFetchAllProductsInstancesResp = yield phanesAPIClient.get(
      `v1/enterprise/${enterpriseId}/products`
    );

    yield put(
      FetchAllProductsInstances.SUCCESS(response.data.productInstancesInfo)
    );
  } catch (error) {
    yield put(FetchAllProductsInstances.ERROR(error as Error));
  }
}

export function* handleFetchAllUsers(): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  try {
    const response: IUser[] | any = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/users`
    );
    yield put(FetchAllUsers.SUCCESS(response.data.items));
  } catch (error) {
    yield put(FetchAllUsers.ERROR(error as Error));
  }
}

export function* commonSaga(): Generator {
  yield takeEvery(ClickAway.TYPE, handleClickAway);
  yield takeEvery(
    [
      DiscardInviteChanges.TYPE,
      DiscardAddGroupChanges.TYPE,
      DiscardCustomRoleStepper.TYPE,
    ],
    handleDiscardChanges
  );
  yield takeLatest(
    FetchAllProductsInstances.Pending.TYPE,
    handleFetchAllProductsInstances
  );
  yield takeLatest(FetchAllUsers.Pending.TYPE, handleFetchAllUsers);
  yield takeLatest(FetchFeatureFlags.Pending.TYPE, handleFetchFeatureFlags);
}
