import { PayloadAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { createReducer } from 'redux-create-reducer';
import { ErrorMessagesType } from 'src/app/enums/AddUsersStepper';
import {
  FetchAllGroups,
  FetchAllRoles,
  FetchGroupRoles,
  SetProductFilterOptions,
  SetRegionFilterOptions,
  SetProductFilterSelectedOption,
  SetRegionFilterSelectedOption,
  SetAssignedRolesTablePagination,
  SetAssignedRolesTableSearch,
  SetDrawerRolesTable,
  SetUserTableSelectedRows,
  SetUsersTablePagination,
  SetUsersTableSearch,
  ClearMapGroupsStepper,
  SetSelectedUsersAddGroupDrawer,
  UpdateGroupsSearchField,
  UpdateTableConfigData,
  ToggleGroupsDrawerVisibility,
  ChangeNameForNewGroupIdpStepper,
  ChangeDescriptionForNewGroupIdpStepper,
  CreateGroup,
  SetMapGroupRowsData,
  FetchGroupMappings,
  SetAssignRolesTableDrawer,
  ToggleAssignRolesDrawerVisibility,
  UpdateGroupMappings,
  CloseMapGroupsErrorBanner,
  FetchIdpGroups,
  SetUsersTableSorting,
  SetRolesTableSorting,
  ClearEditGroup,
  SetAddGroupStep,
  SetTypeFilterSelectedOption,
  SetTypeFilterOptions,
  SetMapIdentityProviderStep,
  FetchGroupUsers,
  DeleteGroup,
  FetchGroupById,
  EditGroup,
  HideDeleteGroupModal,
  ShowDeleteGroupModal,
  ShowAddUsersDrawer,
  HideAddUsersDrawer,
  ShowEditGroupNameDrawer,
  HideEditGroupNameDrawer,
  ShowDiscardChangesModal,
  HideDiscardChangesModal,
  ShowAssignRolesDrawer,
  HideAssignRolesDrawer,
  SetUsersOptionsFromDrawer,
  SetUsersOptions,
  ChangeNameField,
  AddNotification,
  ChangeDescriptionField,
  HideNotification,
  HideAllEditGroupDrawers,
  DiscardAddGroupChanges,
  SetActiveDeleteGroupId,
  ChangeNameFieldFromDrawer,
  ChangeDescriptionFieldFromDrawer,
  SelectActiveDeleteUsers,
  SelectActiveDeleteSingleUser,
  SelectActiveDeleteRole,
  ClearAllEditGroupDrawers,
  HideRemoveModal,
} from './actions';
import { extractProductsFromRoles } from './helpers';
import { IMapGroupFormRow } from './MapIdentityProvidersGroups/interfaces';
import { IGroups, IRolesRows } from '../../interfaces/IGroups';
import { TABLE_CONFIG } from '../../utils/constant';

export const initialState: IGroups = {
  allGroups: [],
  allRoles: [],
  activeEditGroupId: null,
  activeDeleteGroupId: null,
  groupUsers: [],
  groupsTableSearchField: '',
  groupsPagination: {
    pageSize: TABLE_CONFIG.paginationConfig.pageSize,
    pageIndex: 0,
  },
  idpGroups: {
    data: [],
    error: null,
  },
  addGroup: {
    stepper: {
      name: {
        value: '',
        error: true,
        touched: false,
        focused: false,
        errorMessage: 'groups:stepper.groupDetailsStep.nameFieldRequiredError',
      },
      description: {
        value: '',
        touched: false,
        focused: false,
      },
      currentStep: 1,
      usersOptions: [],
      selectedUsersOptions: [],
    },
    newGroupName: '',
  },
  editGroup: {
    editGroupNameDrawer: {
      name: {
        value: '',
        error: true,
        touched: false,
        focused: false,
        errorMessage: 'groups:editGroupDrawer.nameFieldRequiredError',
      },
      description: {
        value: '',
        touched: false,
        focused: false,
      },
    },
    products: [],
    groupRoles: [],
    usersOptionsFromDrawer: [],
    assignRolesTableFromDrawer: [
      {
        selectedProduct: null,
        productsOptions: [],
        rolesOptions: {},
      },
    ],
    activeRemoveAssignedUsers: [],
    activeRemoveUsersCount: 0,
    activeRemoveAssignedSingleUser: null,
    activeRemoveRoleId: null,
    usersTablePagination: {
      pageSize: TABLE_CONFIG.paginationConfig.pageSize,
      pageIndex: 0,
    },
    userTableSelectedRows: {},
    assignedRolesTablePagination: {
      pageSize: TABLE_CONFIG.paginationConfig.pageSize,
      pageIndex: 0,
    },
    assignRolesTable: {
      search: '',
      productFilterOptions: [],
      productFilterSelectedOption: null,
      regionFilterOptions: [],
      regionFilterSelectedOption: null,
      sortingOptions: null,
      typeFilterOptions: [],
      typeFilterSelectedOption: null,
    },
    usersTable: {
      search: '',
      sortingOptions: null,
    },
    assignRolesDrawer: {
      assignRolesTable: [
        {
          selectedRole: null,
          rolesOptions: [],
          instancesOptions: {},
        },
      ],
    },
    editedGroupState: {
      data: null,
    },
  },
  mapGroupsStepper: {
    updateGroupMappingsError: null,
    isSubmittingGroupMappings: false,
    currentStep: 1,
    existingMappings: {
      error: null,
      isLoading: false,
      data: null,
    },
    secondStep: {
      rowsData: [],
      createGroupDrawer: {
        name: {
          value: '',
          error: true,
          touched: false,
          focused: false,
          errorMessage: ErrorMessagesType.REQUIRED,
        },
        description: {
          value: '',
          touched: false,
          focused: false,
        },
        data: {
          items: null,
          error: null,
          isIdpGroupLoading: false,
        },
        isCreateGroupDrawerVisible: false,
      },
    },
  },
  notificationType: null,
  isDiscardChangesModalVisible: false,
  isDeleteGroupModalVisible: false,
  isEditGroupDrawerVisible: false,
  isAddUsersDrawerVisible: false,
  isAssignRolesDrawerVisible: false,
  error: null,
  isLoading: false,
};

export default createReducer(initialState, {
  [FetchAllGroups.Pending.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [FetchAllGroups.Success.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    allGroups: payload,
    error: null,
    isLoading: false,
  }),
  [FetchAllGroups.Error.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    allGroups: [],
    error: payload,
    isLoading: false,
  }),

  [SetDrawerRolesTable.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      assignRolesTableFromDrawer: payload,
    },
  }),

  [FetchAllRoles.Success.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    allRoles: payload,
  }),

  [FetchGroupById.Success.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      editedGroupState: {
        data: payload,
      },
    },
  }),

  [FetchGroupUsers.Success.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    groupUsers: payload,
  }),

  [DeleteGroup.Success.TYPE]: (state: IGroups) => ({
    ...state,
    isDeleteGroupModalVisible: false,
    activeDeleteGroupId: null,
    groupUsers: [],
  }),

  [SetActiveDeleteGroupId.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    activeDeleteGroupId: payload,
    activeEditGroupId: null,
  }),

  [ShowDeleteGroupModal.TYPE]: (state: IGroups) => ({
    ...state,
    isDeleteGroupModalVisible: true,
  }),
  [HideDeleteGroupModal.TYPE]: (state: IGroups) => ({
    ...state,
    isDeleteGroupModalVisible: false,
  }),

  [ChangeNameField.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    addGroup: {
      ...state.addGroup,
      stepper: {
        ...state.addGroup.stepper,
        name: {
          ...state.addGroup.stepper.name,
          ...payload,
        },
      },
      newGroupName: payload.value || state.addGroup.stepper.name.value,
    },
  }),
  [SetAddGroupStep.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    addGroup: {
      ...state.addGroup,
      stepper: {
        ...state.addGroup.stepper,
        currentStep: payload,
      },
    },
  }),
  [ChangeDescriptionField.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    addGroup: {
      ...state.addGroup,
      stepper: {
        ...state.addGroup.stepper,
        description: {
          ...state.addGroup.stepper.description,
          ...payload,
        },
      },
    },
  }),

  [SetUsersOptions.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    addGroup: {
      ...state.addGroup,
      stepper: {
        ...state.addGroup.stepper,
        usersOptions: payload,
      },
    },
  }),

  [ToggleAssignRolesDrawerVisibility.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    isAssignRolesDrawerVisible: payload,
  }),

  [ShowDiscardChangesModal.TYPE]: (state: IGroups) => ({
    ...state,
    isDiscardChangesModalVisible: true,
  }),
  [HideDiscardChangesModal.TYPE]: (state: IGroups) => ({
    ...state,
    isDiscardChangesModalVisible: false,
  }),

  [DiscardAddGroupChanges.TYPE]: (state: IGroups) => ({
    ...state,
    addGroup: {
      ...state.addGroup,
      stepper: initialState.addGroup.stepper,
    },
    isDiscardChangesModalVisible: false,
  }),

  [AddNotification.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    notificationType: payload,
  }),
  [HideNotification.TYPE]: (state: IGroups) => ({
    ...state,
    notificationType: null,
  }),

  [ShowAddUsersDrawer.TYPE]: (state: IGroups) => ({
    ...state,
    isAddUsersDrawerVisible: true,
    isDiscardChangesModalVisible: false,
    isDeleteGroupModalVisible: false,
    isEditGroupDrawerVisible: false,
    isAssignRolesDrawerVisible: false,
  }),
  [HideAddUsersDrawer.TYPE]: (state: IGroups) => ({
    ...state,
    isAddUsersDrawerVisible: false,
  }),

  [ShowEditGroupNameDrawer.TYPE]: (state: IGroups) => ({
    ...state,
    isEditGroupDrawerVisible: true,
    isDiscardChangesModalVisible: false,
    isDeleteGroupModalVisible: false,
    isAddUsersDrawerVisible: false,
    isAssignRolesDrawerVisible: false,
  }),
  [HideEditGroupNameDrawer.TYPE]: (state: IGroups) => ({
    ...state,
    isEditGroupDrawerVisible: false,
  }),

  [ChangeNameFieldFromDrawer.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      editGroupNameDrawer: {
        ...state.editGroup.editGroupNameDrawer,
        name: {
          ...state.editGroup.editGroupNameDrawer.name,
          ...payload,
        },
      },
    },
  }),
  [ChangeDescriptionFieldFromDrawer.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      editGroupNameDrawer: {
        ...state.editGroup.editGroupNameDrawer,
        description: {
          ...state.editGroup.editGroupNameDrawer.description,
          ...payload,
        },
      },
    },
  }),

  [ShowAssignRolesDrawer.TYPE]: (state: IGroups) => ({
    ...state,
    isAssignRolesDrawerVisible: true,
    isDiscardChangesModalVisible: false,
    isDeleteGroupModalVisible: false,
    isEditGroupDrawerVisible: false,
    isAddUsersDrawerVisible: false,
  }),
  [HideAssignRolesDrawer.TYPE]: (state: IGroups) => ({
    ...state,
    isAssignRolesDrawerVisible: false,
  }),

  [EditGroup.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    activeEditGroupId: payload,
  }),

  [SetUsersOptionsFromDrawer.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      usersOptionsFromDrawer: payload,
    },
  }),
  [SetSelectedUsersAddGroupDrawer.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    addGroup: {
      ...state.addGroup,
      stepper: {
        ...state.addGroup.stepper,
        selectedUsersOptions: payload,
      },
    },
  }),

  [UpdateGroupsSearchField.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    groupsTableSearchField: payload,
  }),
  [UpdateTableConfigData.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    groupsPagination: payload,
  }),

  [FetchGroupRoles.Success.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      products: extractProductsFromRoles(payload),
      groupRoles: payload,
    },
  }),

  [HideRemoveModal.TYPE]: (state: IGroups) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      activeRemoveAssignedUsers: [],
      activeRemoveAssignedSingleUser: null,
      activeRemoveRoleId: null,
    },
  }),
  [SelectActiveDeleteUsers.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      activeRemoveUsersCount: payload.length,
      activeRemoveAssignedUsers: payload,
      activeRemoveAssignedSingleUser: null,
    },
  }),
  [SelectActiveDeleteSingleUser.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      activeRemoveAssignedUsers: [],
      activeRemoveUsersCount: 1,
      activeRemoveAssignedSingleUser: payload,
    },
  }),

  [SelectActiveDeleteRole.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      activeRemoveRoleId: payload,
    },
  }),

  [HideAllEditGroupDrawers.TYPE]: (state: IGroups) => ({
    ...state,
    isEditGroupDrawerVisible: false,
    isAddUsersDrawerVisible: false,
    isAssignRolesDrawerVisible: false,
  }),

  [ClearAllEditGroupDrawers.TYPE]: (state: IGroups) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      editGroupNameDrawer: initialState.editGroup.editGroupNameDrawer,
      usersOptionsFromDrawer: initialState.editGroup.usersOptionsFromDrawer,
      assignRolesTableFromDrawer:
        initialState.editGroup.assignRolesTableFromDrawer,
      assignRolesDrawer: initialState.editGroup.assignRolesDrawer,
    },
  }),

  [SetProductFilterOptions.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      assignRolesTable: {
        ...state.editGroup.assignRolesTable,
        productFilterOptions: payload,
      },
    },
  }),
  [SetRegionFilterOptions.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      assignRolesTable: {
        ...state.editGroup.assignRolesTable,
        regionFilterOptions: payload,
      },
    },
  }),
  [SetProductFilterSelectedOption.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      assignRolesTable: {
        ...state.editGroup.assignRolesTable,
        productFilterSelectedOption: payload,
      },
    },
  }),
  [SetRegionFilterSelectedOption.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      assignRolesTable: {
        ...state.editGroup.assignRolesTable,
        regionFilterSelectedOption: payload,
      },
    },
  }),
  [SetTypeFilterOptions.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      assignRolesTable: {
        ...state.editGroup.assignRolesTable,
        typeFilterOptions: payload,
      },
    },
  }),
  [SetTypeFilterSelectedOption.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      assignRolesTable: {
        ...state.editGroup.assignRolesTable,
        typeFilterSelectedOption: payload,
      },
    },
  }),
  [SetAssignedRolesTablePagination.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      assignedRolesTablePagination: payload,
    },
  }),
  [SetUsersTablePagination.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      usersTablePagination: payload,
    },
  }),
  [SetAssignedRolesTableSearch.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      assignRolesTable: {
        ...state.editGroup.assignRolesTable,
        search: payload,
      },
    },
  }),
  [SetRolesTableSorting.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      assignRolesTable: {
        ...state.editGroup.assignRolesTable,
        sortingOptions: payload,
      },
    },
  }),
  [SetUserTableSelectedRows.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      userTableSelectedRows: payload,
    },
  }),
  [SetUsersTableSearch.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      usersTable: {
        ...state.editGroup.usersTable,
        search: payload,
      },
    },
  }),
  [SetUsersTableSorting.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      usersTable: {
        ...state.editGroup.usersTable,
        sortingOptions: payload,
      },
    },
  }),

  [SetAssignRolesTableDrawer.TYPE]: (
    state: IGroups,
    { payload }: PayloadAction<IRolesRows[]>
  ) => ({
    ...state,
    editGroup: {
      ...state.editGroup,
      assignRolesDrawer: {
        ...state.editGroup.assignRolesDrawer,
        assignRolesTable: payload,
      },
    },
  }),

  [SetMapGroupRowsData.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      secondStep: {
        ...state.mapGroupsStepper.secondStep,
        rowsData: payload.map((row: IMapGroupFormRow[], rowIndex: number) => ({
          ...row,
          rowIndex,
        })),
      },
    },
  }),

  [SetMapIdentityProviderStep.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      currentStep: payload,
    },
  }),

  [ClearMapGroupsStepper.TYPE]: (state: IGroups) => ({
    ...state,
    mapGroupsStepper: {
      ...initialState.mapGroupsStepper,
      existingMappings: {
        ...state.mapGroupsStepper.existingMappings,
      },
    },
  }),

  [CreateGroup.Pending.TYPE]: (state: IGroups) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      secondStep: {
        ...state.mapGroupsStepper.secondStep,
        createGroupDrawer: {
          ...state.mapGroupsStepper.secondStep.createGroupDrawer,
          data: {
            ...state.mapGroupsStepper.secondStep.createGroupDrawer.data,
            isIdpGroupLoading: true,
          },
        },
      },
    },
  }),

  [CreateGroup.Success.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      secondStep: {
        ...state.mapGroupsStepper.secondStep,
        createGroupDrawer: {
          ...state.mapGroupsStepper.secondStep.createGroupDrawer,
          name: {
            value: '',
            error: true,
            touched: false,
            focused: false,
            errorMessage: ErrorMessagesType.REQUIRED,
          },
          description: {
            value: '',
            touched: false,
            focused: false,
          },
          data: {
            ...state.mapGroupsStepper.secondStep.createGroupDrawer.data,
            items: payload,
            isIdpGroupLoading: false,
          },
        },
      },
    },
  }),

  [CreateGroup.Error.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      secondStep: {
        ...state.mapGroupsStepper.secondStep,
        createGroupDrawer: {
          ...state.mapGroupsStepper.secondStep.createGroupDrawer,
          data: {
            ...state.mapGroupsStepper.secondStep.createGroupDrawer.data,
            isIdpGroupLoading: false,
            error: payload,
          },
        },
      },
    },
    error: payload,
  }),

  [ToggleGroupsDrawerVisibility.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      secondStep: {
        ...state.mapGroupsStepper.secondStep,
        createGroupDrawer: {
          ...state.mapGroupsStepper.secondStep.createGroupDrawer,
          isCreateGroupDrawerVisible: payload,
        },
      },
    },
  }),

  [ChangeNameForNewGroupIdpStepper.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      secondStep: {
        ...state.mapGroupsStepper.secondStep,
        createGroupDrawer: {
          ...state.mapGroupsStepper.secondStep.createGroupDrawer,
          name: {
            ...state.mapGroupsStepper.secondStep.createGroupDrawer.name,
            ...payload,
          },
        },
      },
    },
  }),

  [ChangeDescriptionForNewGroupIdpStepper.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      secondStep: {
        ...state.mapGroupsStepper.secondStep,
        createGroupDrawer: {
          ...state.mapGroupsStepper.secondStep.createGroupDrawer,
          description: {
            ...state.mapGroupsStepper.secondStep.createGroupDrawer.description,
            ...payload,
          },
        },
      },
    },
  }),

  [FetchGroupMappings.Success.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      existingMappings: {
        error: null,
        isLoading: false,
        data: payload,
      },
    },
  }),

  [FetchGroupMappings.Pending.TYPE]: (state: IGroups) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      existingMappings: {
        ...state.mapGroupsStepper.existingMappings,
        error: null,
        isLoading: true,
      },
    },
  }),

  [FetchGroupMappings.Error.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      existingMappings: {
        ...state.mapGroupsStepper.existingMappings,
        error: payload,
        isLoading: false,
      },
    },
  }),

  [UpdateGroupMappings.Error.TYPE]: (
    state: IGroups,
    { payload }: AnyAction
  ) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      updateGroupMappingsError: payload,
    },
  }),

  [CloseMapGroupsErrorBanner.TYPE]: (state: IGroups) => ({
    ...state,
    mapGroupsStepper: {
      ...state.mapGroupsStepper,
      updateGroupMappingsError: null,
    },
  }),

  [FetchIdpGroups.Success.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    idpGroups: {
      data: payload,
      error: null,
    },
  }),

  [FetchIdpGroups.Error.TYPE]: (state: IGroups, { payload }: AnyAction) => ({
    ...state,
    idpGroups: {
      ...state.idpGroups,
      error: payload,
    },
  }),

  [ClearEditGroup.TYPE]: (state: IGroups) => ({
    ...state,
    editGroup: initialState.editGroup,
  }),
});
