import { phanesAPIClient } from '../api/clients';
import { ComponentType } from '../enums/ProvisioningFieldTypes';
import {
  IProductTransformed,
  IProductDTO,
  IProductListErrorResponse,
  IProductListSuccessResponse,
  IProductTransformedData,
} from '../interfaces/IProductList';
import { productFlags } from '../utils/productVisibleTags';

// -- TODO: Move to /overview saga file
export const fetchProductList = async (
  enterpriseId: string
): Promise<IProductListSuccessResponse | IProductListErrorResponse> => {
  try {
    const response: IProductDTO = await phanesAPIClient.get(
      `/v1/customers?enterprise_id=${enterpriseId}`
    );
    let productTransformedData: IProductTransformedData = {
      allProducts: [],
      pendingProducts: [],
      otherProducts: [],
    };

    if (response.data) {
      productTransformedData = response.data.reduce(
        (finalData: IProductTransformedData, product) => {
          const productEntitlements =
            product?.org_entitlement_instances ?? product.entitlement_instances;
          const entitlementsData = product.is_trial
            ? productEntitlements[0].trial
            : productEntitlements[0].subscription;

          let provisioning_metadata =
            product?.product?.provisioning_metadata ?? [];
          if (
            product.product.provisioning_questions &&
            provisioning_metadata.length === 0
          ) {
            provisioning_metadata = product.product?.provisioning_questions.map(
              (provisioningQuestion) => {
                let questionValue = '';
                if (
                  provisioningQuestion.type === ComponentType.DROP_DOWN &&
                  provisioningQuestion.options.length === 1
                ) {
                  questionValue = provisioningQuestion.options[0].value;
                }
                return {
                  name: provisioningQuestion.name,
                  value: questionValue,
                };
              }
            );
          }
          const productObject: IProductTransformed = {
            productId: product.ID,
            region: product.region,
            subscriptionId: entitlementsData.externalId,
            instanceId: product.external_tenant_id,
            name: product.product.name,
            startDate:
              (product.is_trial
                ? entitlementsData.trial_start_date
                : entitlementsData.requested_start_date) ?? '',
            expiryDate:
              (product.is_trial
                ? entitlementsData.trial_end_date
                : entitlementsData.subscription_end_date) ?? '',
            provisionStatus: product.provisioning_status,
            isTrial: product?.is_trial ?? false,
            isActive: product?.isActive ?? true, // default all products are active.
            isHidden: product?.isHidden ?? false,
            isLegacy: product?.isLegacy ?? false,
            productTags: [],
            isExternal: entitlementsData.is_external ?? false,
            productName: entitlementsData.product_name ?? '',
            canProvision: entitlementsData.is_provisionable ?? false,
            seat: productEntitlements[0].quantity,
            tier: productEntitlements[0].entitlement.value,
            provisioningQuestions: product.product.provisioning_questions ?? [],
            provisioningQuestionsMetaData: provisioning_metadata,
            productAllowedRegions: product?.product?.product_allowed_regions,
            initialAdmin: product?.initialAdmin ?? '',
          } as IProductTransformed;
          productObject.productTags = productFlags(productObject);
          finalData[
            productObject.provisionStatus === 'NEW' ||
            productObject.provisionStatus === 'ACTION_REQUIRED'
              ? 'pendingProducts'
              : 'otherProducts'
          ].push(productObject);
          finalData.allProducts.push(productObject);
          return finalData;
        },
        {
          allProducts: [],
          pendingProducts: [],
          otherProducts: [],
        }
      );
    }

    return {
      isOk: true,
      data: productTransformedData,
      error: null,
    };
  } catch (error) {
    return {
      isOk: false,
      data: null,
      error,
    };
  }
};
