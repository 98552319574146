import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import { CDSTableSelection } from '@ciscodesignsystems/cds-react-table';
import {
  getActionFactory,
  getAsyncActionFactory,
} from '../../core/redux/utils/action-class-factory';
import {
  ChangeTextFieldPayload,
  CustomRoleStepperStepsType,
  IAssignedRolesGroup,
  IAssignUserRolesDrawerData,
  IAssignUserRolesRelationsData,
  IAssignUserRolesRelationsSuccess,
  ICustomRole,
  IEditingRolePayload,
  IGroupResp,
  IModifiedUser,
  IProductPermission,
  IRole,
  IRolesTableConfigPayload,
  IRoleToGroupsRelationsResp,
  IRoleToUserRelation,
  IUnassignGroup,
  IUnassignUser,
  IUserResp,
  IUserRoleNotificationDataType,
  IUserRoleResp,
  PermissionTableScopes,
} from '../../interfaces/IUserRoles';

const createAction = getActionFactory('user-roles');
const createAsyncAction = getAsyncActionFactory('user-roles');

/* ----------------- sync actions ------------------ */
export const ChangeProductField = createAction<CDSOption>(
  'CHANGE_PRODUCT_FIELD'
);
export const ChangeTextField =
  createAction<ChangeTextFieldPayload>('CHANGE_TEXT_FIELD');
export const ClearCustomRoleStepper = createAction('CLEAR_CUSTOM_ROLE_STEPPER');
export const SetActiveEditCustomRoleId = createAction<string | null>(
  'SET_ACTIVE_EDIT_CUSTOM_ROLE_ID'
);

export const SetRolesTableConfigData = createAction<IRolesTableConfigPayload>(
  'SET_USER_ROLES_TABLE_DATA'
);
export const ClearRolesTableConfigData = createAction<null>(
  'CLEAR_ROLES_TABLE_CONFIG_DATA'
);

export const SetAssignUserRolesDrawerData =
  createAction<IAssignUserRolesDrawerData>('SET_ASSIGN_USER_ROLES_DRAWER_DATA');
export const ClearAssignUserRolesDrawerData = createAction<null>(
  'CLEAR_ASSIGN_USER_ROLES_DRAWER_DATA'
);

export const SetPermissionTableSearch = createAction<string>(
  'SET_PERMISSION_TABLE_SEARCH'
);

export const SetPermissionTableSelectedRows = createAction<CDSTableSelection>(
  'SET_PERMISSION_TABLE_SELECTED_ROWS'
);

export const SetPermissionTableSelectedScopes = createAction<string[]>(
  'SET_PERMISSION_TABLE_SELECTED_SCOPES'
);

export const SetCustomRoleStepperStep =
  createAction<CustomRoleStepperStepsType>('SET_CUSTOM_ROLE_STEPPER_STEP');

export const ToggleDiscardChangesModal = createAction<boolean>(
  'TOGGLE_DISCARD_CHANGES_MODAL'
);
export const SetCustomRoleDirty = createAction<boolean>(
  'SET_CUSTOM_ROLE_DIRTY'
);
export const DiscardCustomRoleStepper = createAction<{
  shouldRedirect?: boolean;
}>('DISCARD_CUSTOM_ROLE_STEPPER');

export const ToggleSummaryStepperBanner = createAction<boolean>(
  'TOGGLE_SUMMARY_STEPPER_BANNER'
);

export const UnassignUserFromRoleResetToastState = createAction(
  'UNASSIGN_USER_FROM_ROLE_RESET_TOAST_STATE'
);
export const UnassignGroupFromRoleResetToastState = createAction(
  'UNASSIGN_GROUP_FROM_ROLE_RESET_TOAST_STATE'
);
export const AssignUserRolesRelationsResetToastState = createAction(
  'ASSIGN_USER_ROLES_RELATIONS_RESET_TOAST_STATE'
);

export const SetSelectedUserRoles = createAction<IUserRoleResp[]>(
  'SET_SELECTED_USER_ROLES'
);
export const ClearSelectedUserRoles = createAction('CLEAR_SELECTED_USER_ROLES');
export const ClearSelectedUserRolesToastData = createAction(
  'CLEAR_SELECTED_USER_ROLES_TOAST_DATA'
);

export const SetEditingUserRole = createAction<IEditingRolePayload>(
  'SET_EDITING_USER_ROLE'
);
export const ClearEditingUserRole = createAction('CLEAR_EDITING_USER_ROLE');

export const ToggleDeleteUserRoleModal = createAction<boolean>(
  'TOGGLE_DELETE_USER_ROLE_MODAL'
);

export const AddUserRoleNotification = createAction<{
  data: IUserRoleNotificationDataType;
}>('ADD_USER_ROLE_NOTIFICATION');
export const HideUserRoleNotification = createAction(
  'HIDE_USER_ROLE_NOTIFICATION'
);

export const HandleOnDeleteUserRoleButtonClick = createAction<IUserRoleResp>(
  'HANDLE_ON_DELETE_USER_ROLE_BUTTON_CLICK'
);

export const SetDeleteCustomUserRole = createAction<IUserRoleResp>(
  'SET_DELETE_CUSTOM_USER_ROLE'
);

export const ResetLoaderState = createAction('RESET_LOADER_STATE');

/* ----------------- async actions ------------------ */
export const FetchUserRoles = createAsyncAction<null, IRole[]>(
  'FETCH_USER_ROLES'
);

export const FetchAllEnterpriseUsers = createAsyncAction<null, IUserResp[]>(
  'FETCH_ALL_ENTERPRISE_USERS'
);

export const FetchAllGroups = createAsyncAction<null, IGroupResp[]>(
  'FETCH_ALL_GROUPS'
);

export const FetchRoleToUsersRelations = createAsyncAction<
  string,
  IRoleToUserRelation[]
>('FETCH_ROLE_TO_USERS_RELATIONS');

export const FetchRoleToGroupsRelations = createAsyncAction<
  string,
  IRoleToGroupsRelationsResp[]
>('FETCH_ROLE_TO_GROUPS_RELATIONS');

export const UnassignUserFromRole = createAsyncAction<
  IUnassignUser,
  IModifiedUser
>('UNASSIGN_USER_FROM_ROLE');

export const UnassignGroupFromRole = createAsyncAction<
  IUnassignGroup,
  IAssignedRolesGroup
>('UNASSIGN_GROUP_FROM_ROLE');

export const AssignUserRolesRelations = createAsyncAction<
  IAssignUserRolesRelationsData,
  IAssignUserRolesRelationsSuccess
>('ASSIGN_USER_ROLES_RELATIONS');

export const DeleteUserRole = createAsyncAction<
  string,
  IAssignUserRolesRelationsSuccess
>('DELETE_USER_ROLE');

export const FetchCustomRole = createAsyncAction<
  null,
  { customRole: ICustomRole; tableScopes: PermissionTableScopes }
>('FETCH_CUSTOM_ROLE');

export const FetchProductsCustomRole = createAsyncAction<null, CDSOption[]>(
  'FETCH_PRODUCTS_CUSTOM_ROLE'
);

export const FetchProductPermissions = createAsyncAction<
  null,
  IProductPermission[]
>('FETCH_PRODUCT_PERMISSIONS');

export const CreateCustomRole = createAsyncAction<null, ICustomRole>(
  'CREATE_CUSTOM_ROLE'
);

export const UpdateCustomRole = createAsyncAction<null, ICustomRole>(
  'UPDATE_CUSTOM_ROLE'
);
