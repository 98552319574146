import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import _toUpper from 'lodash/toUpper';
import { createSelector } from 'reselect';
import { FEATURE_FLAG_PREFIX } from 'src/app/utils/constant';
import { initialState } from './reducer';
import { IState } from '../../core/redux/interfaces';
import {
  IRoleDetails,
  IUserRolesState,
  PermissionTableScopes,
} from '../../interfaces/IUserRoles';
import { getFeatureFlagsSet } from '../common/selectors';

export const getState = (state: IState): IUserRolesState =>
  state.userRoles || initialState;

export const getAllEnterpriseUsers = createSelector(
  // -- TODO: Remove, getAllUsers alredy exists in /common selectors.ts file
  [getState],
  ({ allEnterpriseUsers }) => allEnterpriseUsers
);

export const getSelectedUserRoles = createSelector(
  [getState],
  ({ selectedUserRoles }) => selectedUserRoles
);

export const getEditingUserRole = createSelector(
  [getState],
  ({ editingUserRole }) => editingUserRole
);

export const getAssignUserRolesDrawerData = createSelector(
  [getState],
  ({ assignUserRolesDrawerData }) => assignUserRolesDrawerData
);

export const getRolesTableConfigData = createSelector(
  [getState],
  ({ rolesTableConfigData }) => rolesTableConfigData
);

export const getUserRoles = createSelector(
  [getState],
  ({ userRoles }) => userRoles
);

export const getAllGroups = createSelector(
  [getState],
  ({ allGroups }) => allGroups
);

export const getIsUserRolesLoading = createSelector(
  [getUserRoles],
  (userRoles) => userRoles.loading
);

export const getUserRolesLoadingErrors = createSelector(
  [getUserRoles],
  (userRoles) => userRoles.error
);

export const getRoleToUsersRelations = createSelector(
  [getState],
  ({ roleToUsersRelations }) => roleToUsersRelations
);

export const getUnassignUserFromRole = createSelector(
  [getState],
  ({ unassignUserFromRole }) => unassignUserFromRole
);

export const getUnassignGroupFromRole = createSelector(
  [getState],
  ({ unassignGroupFromRole }) => unassignGroupFromRole
);

export const getRoleToGroupsRelations = createSelector(
  [getState],
  ({ roleToGroupsRelations }) => roleToGroupsRelations
);

export const getUserRolesTableLoading = createSelector(
  [getUserRoles, getAllEnterpriseUsers],
  (userRoles, allEnterpriseUsers) =>
    userRoles.loading || allEnterpriseUsers.loading
);

export const getUnassignUserFromRoleTableLoading = createSelector(
  [getUserRoles, getRoleToUsersRelations, getUnassignUserFromRole],
  (userRoles, roleToUsersRelations, unassignUserFromRole) =>
    userRoles.loading ||
    roleToUsersRelations.loading ||
    unassignUserFromRole.loading
);

export const getUnassignGroupFromRoleTableLoading = createSelector(
  [getUnassignGroupFromRole, getUserRoles, getRoleToGroupsRelations],
  (unassignGroupFromRole, userRoles, roleToGroupsRelations) =>
    unassignGroupFromRole.loading ||
    userRoles.loading ||
    roleToGroupsRelations.loading
);

export const getAssignRolesDrawerLoading = createSelector(
  [getAllEnterpriseUsers, getAllGroups, getSelectedUserRoles],
  (allUsers, allGroups, selectedUserRoles) =>
    allUsers.loading || allGroups.loading || selectedUserRoles.loading
);

export const getCustomRoleState = createSelector(
  [getState],
  ({ customRoles }) => customRoles
);

export const getIsDeleteUserRoleModalVisible = createSelector(
  [getCustomRoleState],
  ({ isDeleteUserRoleModalVisible }) => ({
    isDeleteUserRoleModalVisible,
  })
);

export const getActiveDeleteUserRole = createSelector(
  [getCustomRoleState],
  ({ deleteCustomUserRole }) => deleteCustomUserRole
);

export const getDeleteUserRoleModalData = createSelector(
  [getCustomRoleState],
  ({ deleteCustomUserRole }) => ({
    roleName: deleteCustomUserRole.roleDisplayName,
    roleId: deleteCustomUserRole.id,
  })
);

export const getUserRoleNotification = createSelector(
  [getState],
  ({ notification }) => ({
    notificationType: notification.type,
    roleName: notification.data,
    options: notification.options,
  })
);

export const getProductPermissions = createSelector(
  [getCustomRoleState],
  ({ productPermissions }) => productPermissions
);

export const getFilteredProductPermissions = createSelector(
  [getCustomRoleState, getFeatureFlagsSet],
  ({ productPermissions, permissionTableSearch }, userFeatureFlags) =>
    productPermissions.filter((permission) => {
      return (
        (!permissionTableSearch ||
          permission.name
            .toLowerCase()
            .includes(permissionTableSearch.toLowerCase())) &&
        new Set(
          permission.featureFlags?.map((flag) =>
            _toUpper(flag).replace(FEATURE_FLAG_PREFIX, '')
          ) ?? []
        ).isSubsetOf(userFeatureFlags)
      );
    })
);

export const getIsCustomRoleLoading = createSelector(
  [getCustomRoleState],
  ({ isLoading }) => isLoading
);

export const getPermissionTableSearch = createSelector(
  [getCustomRoleState],
  ({ permissionTableSearch }) => permissionTableSearch
);

export const getPermissionTableSelectedRows = createSelector(
  [getCustomRoleState],
  ({ permissionTableSelectedRows }) => permissionTableSelectedRows
);

export const getPermissionTableSelectedScopes = createSelector(
  [getCustomRoleState],
  ({ permissionTableSelectedScopes }) => permissionTableSelectedScopes
);

export const getSelectedPermissions = createSelector(
  [getCustomRoleState, getPermissionTableSelectedScopes],
  ({ productPermissions }, selectedScopes: PermissionTableScopes) =>
    productPermissions.filter((permission) => selectedScopes[permission.scope])
);

export const getActiveEditCustomRoleId = createSelector(
  [getCustomRoleState],
  ({ activeEditCustomRoleId }) => activeEditCustomRoleId
);

export const getIsDiscardChangesModalVisible = createSelector(
  [getCustomRoleState],
  ({ isDiscardChangesModalVisible }) => isDiscardChangesModalVisible
);

export const getSummaryStepperBanner = createSelector(
  [getCustomRoleState],
  ({ toggleSummaryStepperBanner }) => toggleSummaryStepperBanner
);

export const getFetchEditCustomRole = createSelector(
  [getCustomRoleState],
  ({ fetchedEditCustomRole }) => fetchedEditCustomRole
);

export const getActiveEditCustomRoleName = createSelector(
  [getCustomRoleState],
  ({ fetchedEditCustomRole }) => fetchedEditCustomRole?.roleDisplayName
);

export const getStepper = createSelector(
  [getCustomRoleState],
  ({ stepper }) => stepper
);

export const getStepperStep = createSelector(
  [getStepper],
  ({ stepperStep }) => stepperStep
);

export const getProductOptions = createSelector(
  [getStepper],
  ({ productOptions, productField }) =>
    productField.value
      ? productOptions.items.map((option: CDSOption) => ({
          ...option,
          selected: option.value === productField.value?.value,
        }))
      : productOptions.items
);

export const getSelectedProductId = createSelector(
  [getStepper],
  ({ productField }) => productField?.value?.value
);

export const getIsProductOptionsLoading = createSelector(
  [getStepper],
  ({ productOptions }) => productOptions.isLoading
);

export const getRoleDetailsStepFields = createSelector(
  [getStepper],
  ({ productField, roleNameField, roleDescriptionField }): IRoleDetails => ({
    productField,
    roleNameField,
    roleDescriptionField,
  })
);

export const getIsCustomRoleDirty = createSelector(
  [getStepper],
  ({ productField, roleNameField }) =>
    Boolean(productField.value?.value ?? roleNameField.value)
);
